import React from "react";
import Slider from "react-slick";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"; // Importing arrow icons
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import img2 from "../../assets/slide3.jpg";
import img1 from "../../assets/SliderImage/slide6.jpg";
import img6 from "../../assets/SliderImage/slide1.JPG";
import img4 from "../../assets/SliderImage/slide3.jpg";
import img2 from "../../assets/SliderImage/slide5.jpg";
import img3 from "../../assets/SliderImage/slide2.jpg";
import img5 from "../../assets/SliderImage/slide4.jpg";

const CustomPrevArrow = ({ onClick }) => {
  return (
    <div
      className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md cursor-pointer z-10"
      onClick={onClick}
    >
      <FaArrowLeft className="text-gray-800" />
    </div>
  );
};

const CustomNextArrow = ({ onClick }) => {
  return (
    <div
      className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md cursor-pointer z-10"
      onClick={onClick}
    >
      <FaArrowRight className="text-gray-800" />
    </div>
  );
};

function CustomSlider() {
  const sliderSettings = {
    dots: false, // show dots at the bottom
    infinite: true, // Infinite loop sliding
    speed: 500, // transition speed
    slidesToShow: 1, // How many slides to show
    slidesToScroll: 1, // How many slides to scroll per click
    arrows: true, // Left and right arrows
    nextArrow: <CustomNextArrow />, // Custom next arrow
    prevArrow: <CustomPrevArrow />, // Custom previous arrow

    responsive: [
      {
        breakpoint: 1024, // Tablet
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768, // Mobile
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const images = [img1, img6, img2, img3, img4, img5];

  return (
    <div className="w-full   ">
      <Slider {...sliderSettings}>
        {images.map((image, index) => (
          <div
            key={index}
            className=" h-full !my-0 flex justify-center  relative"
          >
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              className="w-full lg:h-[45rem]   bg-no-repeat  left-0 right-0 mx-auto"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default CustomSlider;
