// import React from "react";
// import "./CustomButton.css";

// function CustomBtn() {
//   return (
//     <button className="relative border-2 border-gray-800 bg-transparent py-2.5 px-5 font-medium uppercase text-gray-800 transition-colors rounded-full overflow-hidden before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left before:scale-x-0 before:bg-[#293A61] before:rounded-full before:transition-transform before:duration-300 before:content-[''] hover:text-white before:hover:scale-x-100">
//       Button One
//     </button>
//   );
// }

// export default CustomBtn;


import React from "react";
import "./CustomButton.css";


function CustomBtn({
  onClick,
  label,
  className,
  children,
  hasIcon = false,
  disabled,
}) {
  return hasIcon ? (
    <button
      className={` relative border-2 border-gray-800 bg-transparent py-2.5 px-5 font-medium uppercase text-gray-800 transition-colors rounded-full overflow-hidden before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left before:scale-x-0 before:bg-[#293A61] before:rounded-full before:transition-transform before:duration-300 before:content-[''] hover:text-white before:hover:scale-x-100   ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      <p className="flex items-center">
        {children} <span className="mx-2"> {label}</span>
      </p>
    </button>
  ) : (
    <button
      className={` relative border-2 border-gray-800 bg-transparent py-2.5 px-5 font-medium uppercase text-gray-800 transition-colors rounded-full overflow-hidden before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left before:scale-x-0 before:bg-[#293A61] before:rounded-full before:transition-transform before:duration-300 before:content-[''] hover:text-white before:hover:scale-x-100   ${className}`}
      onClick={onClick}
    >
      {label}
    </button>

    // <button
    //   className={` relative border-2 border-[#012B53] bg-transparent py-2.5 px-5 font-medium uppercase text-[#012B53] transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left before:scale-x-0 before:bg-[#012B53] before:transition-transform before:duration-300 before:content-[''] hover:text-white before:hover:scale-x-100    ${className}`}
    //   onClick={onClick}
    // >
    //   {label}
    // </button>
  );
}

export default CustomBtn;
