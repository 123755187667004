import React, { useState } from "react";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import { MdEmail, MdLocalPhone } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { MOTILAL_STRINGS } from "../../StringConstant/MotiLalAllConstant";
import CustomBtn from "../../Shared/CustomButton/CustomBtn";

function Header() {
  const navigate = useNavigate();
  const [menuHoverStyle, setMenuHoverStyle] = useState(0);
  const menu = [
    {
      title: "home",
      link: "/",
    },
    {
      title: "about",
      link: "#",
    },
    {
      title: "academics",
      link: "#",
    },
    {
      title: " beyond academics",
      link: "#",
    },
    {
      title: " gallery",
      link: "/gallery",
    },
    // {
    //   title: " admissions",
    //   link: "#",
    // },
    {
      title: " download",
      link: "#",
    },
    // {
    //   title: " career",
    //   link: "#",
    // },
    // {
    //   title: " get in touch",
    //   link: "#",
    // },
  ];

  const handleMenuItemhover = (index) => {
    if (index === index) {
      setMenuHoverStyle(index);
    }
  };

  return (
    <div className="">
      <div className="w-full  bg-[#012B53] flex items-center">
        <CustomButton
          label={`Notice`}
          className={`bg-[#012B53] hover:bg-[#293B61] border-none !text-xs text-white rounded-none !py-1`}
        />
        <marquee behavior="" direction="" className={`text-white text-xs me-5`}>
          {/* Admission Open For The Year 2025-26. Contact Us For More Information. */}
          {MOTILAL_STRINGS?.NOTICE_FOR_HEADER}
        </marquee>
      </div>
      <div className="flex justify-center items-center w-full">
        <div className="flex justify-between items-center lg:w-[80%]  py-1 ">
          <div className="font-bold text-[#404040] text-sm">
            <p className="text-[#ED0C0A] mb-2 font-extrabold">CALL US :</p>
            <p className="flex items-center gap-2">
              <MdLocalPhone className="text-lg" />
              {MOTILAL_STRINGS?.SCHOOL_PHONE_NO}
            </p>
            <p className="flex items-center gap-2">
              <MdEmail className="text-lg" />
              {MOTILAL_STRINGS?.SCHOOL_EMAIL_ID}
            </p>
          </div>
          <div className=" cursor-pointer" onClick={() => navigate("/")}>
            <img
              src={require("../../assets/school_logo.png")}
              alt=""
              className="w-[8rem]"
            />
          </div>{" "}
          <div className="">
            {/* <CustomButton
              label={`ADMISSION ENQUIRY`}
              className={`bg-[#14A2B8] text-white hover:bg-[#ED0C0A] duration-300 border-none !p-5  font-extrabold `}
            /> */}
            <CustomBtn label={`ADMISSION ENQUIRY`} className={``} />
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center w-full bg-[#293B61] text-white">
        <div
          className={`flex justify-between items-center lg:w-[90%] xl:w-[80%]  gap-10 uppercase font-bold text-sm cursor-pointer`}
        >
          {menu?.map((items, index) => (
            <div
              key={index}
              className={` py-5 duration-200 ${
                menuHoverStyle === index ? " scale-110" : ""
              }`}
              onMouseEnter={() => handleMenuItemhover(index)}
              onMouseLeave={() => handleMenuItemhover(-1)}
              onClick={() => navigate(`${items?.link}`)}
            >
              {items?.title}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Header;
