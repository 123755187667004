import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import MobileHeader from "../Header/MobileHeader";
import WellcomeBox from "../WellcomeBox/WellcomeBox";
import Cards from "../Cards/Cards";
import PrincipleBox from "../PrincipleBox/PrincipleBox";
import SchoolEvents from "../SchoolEvents/SchoolEvents";
import Enquery from "../Enquery/Enquery";
import Footer from "../Footer/Footer";
import AdmissionFormPopup from "../AdmissionFormPopup/AdmissionFormPopup";
import CustomSlider from "../Slider/Slider";
import LoaderWithLogo from "../../AllLoaders/LoaderWithLogo";

import "./HomePage.css";

function HomePage() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Set a timeout to hide the loader after 3 seconds
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading ? (
        // Show the loader while isLoading is true
        <LoaderWithLogo />
      ) : (
        // Show the rest of the content after 3 seconds with animations
        <div className="fade-in">
          {/* <AdmissionFormPopup /> */}
          <div className="hidden lg:block slide-in-right">
            <Header />
          </div>
          <div className="block lg:hidden slide-in-right">
            <MobileHeader />
          </div>

          <div className="slide-in-left">
            <CustomSlider />
          </div>
          <div className="slide-in-right">
            <WellcomeBox />
          </div>
          <div className="slide-in-bottom">
            <Cards />
          </div>
          {/* <div className="slide-in-top">
            <PrincipleBox />
          </div> */}
          <div className="slide-in-left">
            <SchoolEvents />
          </div>
          <div className="slide-in-right">
            <Enquery />
          </div>
          <div className="slide-in-bottom">
            <Footer />
          </div>
        </div>
      )}
    </>
  );
}

export default HomePage;
